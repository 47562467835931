import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SupportedLanguage } from './entities/SupportedLanguage';
import enUS from './locales/en-US.json';
import zhCN from './locales/zh-CN.json';
import { getSsrContext } from './ssr/ssrContext';

i18n.use(initReactI18next).init({
  fallbackLng: 'en-US',
  resources: {
    'en-US': { translation: enUS },
    'zh-CN': { translation: zhCN },
  },
});

if (getSsrContext().language) {
  i18n.changeLanguage(getSsrContext().language);
} else if (process.env.NODE_ENV === 'development') {
  // 开发时可更改此选项以调试对应的语言
  const lang: SupportedLanguage = 'en-US';
  i18n.changeLanguage(lang);
}
