// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/root/workspace/fe-cms-web-qa/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';
import LoadingComponent from '@/bizComponents/pageLoading';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'bizComponents__application' */'@/bizComponents/application'), loading: LoadingComponent}),
    "routes": [
      {
        "path": "/",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/forgot",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/join_us",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'@/pages/home'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/logout",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__logout' */'@/pages/logout'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/connect/shopify",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__connect__shopify' */'@/pages/connect/shopify'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/connect/shopify_auth",
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__connect__shopifyAuth' */'@/pages/connect/shopifyAuth'), loading: LoadingComponent}),
        "exact": true
      },
      {
        "path": "/sitemap",
        "routes": [
          {
            "path": "/sitemap/:name",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__sitemap__v2' */'@/pages/sitemap/v2'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/youtube",
        "routes": [
          {
            "path": "/youtube/:name",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__youtube__youtubers' */'@/pages/youtube/youtubers'), loading: LoadingComponent}),
            "title": "创作者列表",
            "exact": true
          },
          {
            "path": "/youtube/youtubers/:name_id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__youtube__youtuberDetails' */'@/pages/youtube/youtuberDetails'), loading: LoadingComponent}),
            "title": "网红详情",
            "exact": true
          },
          {
            "path": "/youtube/categories/:name_id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__youtube__categories' */'@/pages/youtube/categories'), loading: LoadingComponent}),
            "title": "产品线-分析",
            "exact": true
          },
          {
            "path": "/youtube/brands/:name_id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__youtube__brands' */'@/pages/youtube/brands'), loading: LoadingComponent}),
            "title": "品牌-分析",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/about",
        "routes": [
          {
            "path": "/about/contact-us",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__contactUs' */'@/pages/about/contactUs'), loading: LoadingComponent}),
            "title": "联系我们",
            "exact": true
          },
          {
            "path": "/about/about-us",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__aboutUs' */'@/pages/about/aboutUs'), loading: LoadingComponent}),
            "title": "关于我们",
            "exact": true
          },
          {
            "path": "/about/faq",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__faq' */'@/pages/about/faq'), loading: LoadingComponent}),
            "title": "常见问题",
            "exact": true
          },
          {
            "path": "/about/terms",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__termsOfService' */'@/pages/about/termsOfService'), loading: LoadingComponent}),
            "title": "服务条款",
            "exact": true
          },
          {
            "path": "/about/privacy",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__about__privacyPolicy' */'@/pages/about/privacyPolicy'), loading: LoadingComponent}),
            "title": "隐私政策",
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "path": "/partner",
        "routes": [
          {
            "path": "/partner/:name",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__partner__youtubers' */'@/pages/partner/youtubers'), loading: LoadingComponent}),
            "exact": true
          },
          {
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'@/pages/404'), loading: LoadingComponent}),
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
