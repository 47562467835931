import { getUrlParam } from '../url';

const HOSTNAME = ['g.zbanx.com', 'zbanx-oa-image'];

const SUFFIX = ['jpg', 'jpeg', 'png', 'gif'];

export function transformToWebp(url: string) {
  if (url) {
    const isExist = HOSTNAME.find((item) => url.indexOf(item) > -1);
    if (isExist) {
      const urlObj = new URL(url);
      const suffix = getSuffix(urlObj.pathname).toLowerCase();
      if (!SUFFIX.includes(suffix)) return url;
      if (url.indexOf('format,webp') > -1) return url;
      const params = getUrlParam(url, 'x-oss-process').split('/');
      if (params.length) {
        params.push('format,webp');
      } else {
        params.push('image');
        params.push('format,webp');
      }
      return `${urlObj.origin}${urlObj.pathname}?x-oss-process=${params.join(
        '/',
      )}`;
    }
  }
  return url;
}

export function getSuffix(url: string) {
  if (typeof url !== 'string') {
    return '';
  }
  const index = url.lastIndexOf('.');
  return url.substring(index + 1);
}
