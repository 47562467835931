import i18n from 'i18next';
import { setSsrContext, SsrContext } from './ssrContext';

if (process.env.NODE_ENV === 'development') {
  if (typeof window === 'undefined') {
    console.log('mock window on node env in development');
    // @ts-ignore
    global.window = {
      // @ts-ignore
      BlobBuilder: function () {
        return { append: () => {}, getBlob: () => {} };
      },
      // @ts-ignore
      addEventListener: function () {},
    };
    // @ts-ignore
    global.Worker = function () {};
    // @ts-ignore
    global.URL.createObjectURL = function () {};
  }
}

export const ssr = {
  beforeRenderServer: ({
    context,
  }: {
    context: {
      ssrContext: SsrContext;
    };
  }) => {
    if (!context || !context.ssrContext) {
      if (process.env.NODE_ENV === 'development') {
        // @ts-ignore
        global.window = {
          // @ts-ignore
          BlobBuilder: function () {
            return { append: () => {}, getBlob: () => {} };
          },
          // @ts-ignore
          addEventListener: function () {},
        };
        // @ts-ignore
        global.Worker = function () {};
        // @ts-ignore
        global.URL.createObjectURL = function () {};
      }
    }

    if (context && context.ssrContext) {
      setSsrContext(context.ssrContext);
      if (context.ssrContext.language) {
        i18n.changeLanguage(context.ssrContext.language);
      }
    }
  },
};
