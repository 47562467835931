import { transformToWebp } from '@/utils/image';
import React from 'react';
import { IMAGE_GLOBAL_LOADING } from '../images/globalLoading';
import css from './index.less';

export interface Props {
  loading?: boolean;
  height?: number;
  fullScreen?: boolean;
  size?: 'large' | 'middle' | 'small';
}

export const LoadingBlock: React.FC<Props> = ({
  loading,
  height,
  fullScreen,
  size,
}) => {
  const boxHeightStyle = React.useMemo<React.CSSProperties>(
    () => ({
      height: fullScreen ? undefined : `${height || 300}px`,
    }),
    [height, fullScreen],
  );

  if (!loading) {
    return null;
  }

  return (
    <div
      className={css.loadingBlock}
      data-full-screen={fullScreen}
      style={boxHeightStyle}
    >
      <div className={css.centered}>
        <img
          data-size={size || 'middle'}
          src={transformToWebp(IMAGE_GLOBAL_LOADING)}
          alt="loading"
        />
      </div>
    </div>
  );
};

export default LoadingBlock;
