import React from 'react';
import LoadingBlock from '../loadingBlock';

export interface Props {}

export const PageLoading: React.FC<Props> = () => {
  return <LoadingBlock loading fullScreen />;
};

export default PageLoading;
