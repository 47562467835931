import Cookies from 'js-cookie';
import { getAppSettings } from '@/shared/getAppSettings';
import { isBrowser } from 'umi';

const AUTH_TOKEN = 'auth_token';
const AUTH_TOKEN_EXPIRES_AT = 'auth_token_expires_at';
const SHOP_ID = 'shop_id';
const INVITATION_CODE = 'invite_code';
const COOKIE_CONSENT = 'cookie_consent';
const RELATION_TYPE = 'relation_type';

const cookieName = (name: string) => {
  const { cookiePrefix } = getAppSettings();

  let prefix: string | undefined;

  if (typeof cookiePrefix === 'string') {
    prefix = cookiePrefix.trim();
  }

  return prefix ? `${prefix}${name}` : name;
};

const cookieDomain = () => {
  const domain = getAppSettings().cookieDomain;
  const hostname =
    location.hostname ||
    location.href.replace(/^[0-9a-z]+\:\/\//, '').replace(/\/.*$/, '');

  if (hostname && domain && `.${hostname}`.includes(domain)) {
    return { domain };
  } else {
    console.warn(
      `Invalid cookie domain: ${domain}, current hostname is ${hostname}`,
    );
  }

  return {};
};

const setCookie = (name: string, value: string, expires?: number | Date) => {
  if (isBrowser()) {
    Cookies.set(cookieName(name), value, {
      ...cookieDomain(),
      path: '/',
      expires,
    });
  }
};

const getCookie = (name: string) => {
  return isBrowser() ? Cookies.get(cookieName(name)) : undefined;
};

const removeCookie = (name: string) => {
  Cookies.remove(cookieName(name), { ...cookieDomain(), path: '/' });
};

export const setAuthToken = (token: string, ttlInSeconds: number) => {
  const endTime = new Date(Date.now() + ttlInSeconds * 1000);
  setCookie(AUTH_TOKEN, token, endTime);
  setCookie(AUTH_TOKEN_EXPIRES_AT, `${endTime.getTime()}`, endTime);
};

export const getAuthToken = () => {
  return getCookie(AUTH_TOKEN);
};

export const getAuthTokenExpiresAt = () => {
  return getCookie(AUTH_TOKEN_EXPIRES_AT);
};

export const removeAuthToken = () => {
  removeCookie(AUTH_TOKEN);
  removeCookie(AUTH_TOKEN_EXPIRES_AT);
};

export const setShopId = (shopId: string) => {
  setCookie(SHOP_ID, shopId, 30);
};

export const getShopId = () => {
  return getCookie(SHOP_ID);
};

export const removeShopId = () => {
  removeCookie(SHOP_ID);
};

export const setRelationType = (type: 'merchant' | 'partner') => {
  setCookie(RELATION_TYPE, type, 365);
};

export const getRelationType = () => {
  return getCookie(RELATION_TYPE) as 'merchant' | 'partner' | undefined;
};

export const removeRelationType = () => {
  removeCookie(RELATION_TYPE);
};

export const setInvitationCode = (invitationCode: string) => {
  setCookie(INVITATION_CODE, invitationCode, 30);
};

export const getCookieConsent = () => {
  return getCookie(COOKIE_CONSENT);
};

export const setCookieConsent = (value: string, expires?: Date | number) => {
  setCookie(COOKIE_CONSENT, value, expires);
};

export const removeCookieConsent = () => {
  removeCookie(COOKIE_CONSENT);
};
