export { ssr } from './ssr/settings';

import '@zbanx-coding/components-basic/es/style/zbx.less';
import { registerRequestMethod } from '@zbanx-coding/cms-shared-request';
import '@zbanx-coding/components-shared/es/index.less';
import { configResponsive } from 'ahooks';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import i18n from 'i18next';
import { Helmet, request as requestMethod, RequestConfig } from 'umi';
import { SupportedLanguage } from './entities/SupportedLanguage';
import './i18n';
import './index.less';
import { getAuthToken, getRelationType, getShopId } from './persistence/cookie';
import { getAppSettings } from './shared/getAppSettings';
import { registerGetOssUrl } from '@zbanx-coding/components-shared';
import { getOssUrl } from './utils/oss/getOssUrl';

dayjs.extend(localizedFormat);
registerRequestMethod(requestMethod);
registerGetOssUrl(getOssUrl);

// 自适应配置
configResponsive({
  medium: 1489,
  large: 1441,
});

// 增加自定义参数声明
declare module 'umi-request' {
  interface RequestOptionsInit {
    // 是否禁止自动设置认证 header
    disableAutoSetAuthHeaders?: boolean;
    language?: SupportedLanguage;
    shopId?: string;
    relationType?: 'merchant' | 'partner';
  }
}

export const request: RequestConfig = {
  prefix: getAppSettings().apiHost,
  errorConfig: {
    adaptor: (data, { res }) => {
      if (typeof data === 'string' && res.status >= 400) {
        return {
          success: false,
          errorCode: `${res.status}`,
          errorMessage: res.statusText,
        };
      }

      if (data) {
        // PHP 接口
        if (typeof data.code === 'number') {
          if (data.code === 0) {
            return {
              ...data,
              success: true,
            };
          } else {
            return {
              ...data,
              success: false,
              errorMessage: data.msg,
              errorCode: data.errorCode || `${data.code}`,
            };
          }
          // Java 接口错误报错
        } else if (data.success === false) {
          data.errorMessage = data.errorMessage || data.errorMsg;
        }
      }

      return data;
    },
  },
  requestInterceptors: [
    // 检查 api 地址是不是绝对路径，如果是则不自动添加 prefix
    (url, options) => {
      if (url && options.prefix && url.startsWith(options.prefix)) {
        const originUrl = url.substring(options.prefix.length);
        // 检查是否以 '//' 或 'xxxx://' 开头，如果是则为绝对路径
        if (/^([a-z][0-9a-z]+\:)?\/\//i.test(originUrl)) {
          return {
            url: originUrl,
            options: {
              ...options,
              prefix: undefined,
            },
          };
        }
      }
      return { url, options };
    },
    // 自动注入 Authorization，X-Language 和 X-Shop-Id 请求头
    (url, options) => {
      // 如果禁止自动设置认证 header 则直接返回
      if (options && options.disableAutoSetAuthHeaders) {
        return { url, options };
      }

      const authorization = getAuthToken();
      const shopId = options?.shopId || getShopId();
      const relationType = options?.relationType || getRelationType();
      const extraHeaders: [string, string][] = [
        ['X-Language', options?.language || i18n.language],
      ];

      if (authorization) {
        extraHeaders.push(['Authorization', `Bearer ${authorization}`]);
      }

      if (shopId) {
        extraHeaders.push(['X-Shop-Id', shopId]);
      }

      if (relationType) {
        extraHeaders.push(['X-Relation-Type', relationType]);
      }

      const headersIsRecord = (
        headers?: HeadersInit,
      ): headers is Record<string, string> => {
        if (headers) {
          if (Array.isArray(headers)) {
            return false;
          }

          try {
            if (typeof Headers !== 'undefined' && headers instanceof Headers) {
              return false;
            }
          } catch (err) {
            // no empty
          }

          return true;
        }

        return false;
      };

      if (extraHeaders.length) {
        options.headers = options.headers || {};

        if (headersIsRecord(options.headers)) {
          for (const [headerName, headerValue] of extraHeaders) {
            options.headers[headerName] = headerValue;
          }
        } else if (Array.isArray(options.headers)) {
          for (const [headerName, headerValue] of extraHeaders) {
            options.headers.push([headerName, headerValue]);
          }
        } else {
          for (const [headerName, headerValue] of extraHeaders) {
            options.headers.append(headerName, headerValue);
          }
        }
      }

      return {
        url,
        options,
      };
    },
  ],
};

if (Helmet.defaultProps) {
  Helmet.defaultProps.encodeSpecialCharacters = false;
}
