import { AppSettings } from '@/entities/AppSettings';
import { getSsrContext } from '@/ssr/ssrContext';

declare const APP_SETTINGS: AppSettings;

export const getAppSettings = (): AppSettings => {
  return {
    ...(APP_SETTINGS || {}),
    ...(getSsrContext().appSettings || {}),
  };
};
