/**
 * 获取params参数
 * @param url
 * @param paraName params Key
 */
export function getUrlParam(url: string, paraName: string) {
  const arrObj = url.split('?');

  if (arrObj.length > 1) {
    const arrPara = arrObj[1].split('&');
    let arr;

    for (let i = 0; i < arrPara.length; i++) {
      arr = arrPara[i].split('=');

      if (arr != null && arr[0] == paraName) {
        return arr[1];
      }
    }
  }
  return '';
}

// 验证url有没有带协议头，没有就加//
export function verifyHttp(url?: string) {
  if (!url) return '';
  // eslint-disable-next-line @typescript-eslint/no-shadow
  const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
  if (reg.test(url)) return url;
  return `//${url}`;
}
