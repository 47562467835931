import { Account } from '@/entities/Account';
import { AccountRelation } from '@/entities/AccountRelation';
import { AppSettings } from '@/entities/AppSettings';
import { SupportedLanguage } from '@/entities/SupportedLanguage';
import { isLanguageSupported } from '../utils/isLanguageSupported';

export interface SsrContext {
  language?: SupportedLanguage;
  appSettings?: AppSettings;
  account?: Account;
  relations?: AccountRelation[];
}

declare global {
  interface Window {
    SSR_CONTEXT: SsrContext;
  }
}

let ssrContext: SsrContext = {};

if (typeof window !== 'undefined' && window.SSR_CONTEXT) {
  if (isLanguageSupported(window.SSR_CONTEXT.language)) {
    ssrContext.language = window.SSR_CONTEXT.language;
  }

  if (window.SSR_CONTEXT.appSettings) {
    ssrContext.appSettings = window.SSR_CONTEXT.appSettings;
  }

  if (window.SSR_CONTEXT.account) {
    ssrContext.account = window.SSR_CONTEXT.account;
  }

  if (window.SSR_CONTEXT.relations) {
    ssrContext.relations = window.SSR_CONTEXT.relations;
  }
}

export const setSsrContext = (context: SsrContext) => {
  ssrContext = context;
};

export const getSsrContext = () => {
  return ssrContext;
};
