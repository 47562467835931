export const isAbsoluteUrl = (url: string) => {
  if (typeof url === 'string') {
    return /^([a-z][a-z0-9]*:)?\/\//i.test(url);
  }
  return false;
};

// 获取最后一个元素id
export const getLastUrlId = (url: string) => {
  if (typeof url === 'string') {
    const site = url.lastIndexOf('_');
    const id = url.substring(site + 1, url.length);
    return Number(id);
  }
  return '';
};

// 获取最后一个元素id和倒数第二个元素
export const getLastUrlIdAndLastUrl = (url: string) => {
  const secondID = url.split('-').slice(-1)[0];
  const firstID = url.split('-').slice(-2)[0];

  return { firstID, secondID };
};
