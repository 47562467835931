import { getAppSettings } from '@/shared/getAppSettings';
import { isAbsoluteUrl } from '../url/isAbsoluteUrl';

export function getOssUrl(url?: string) {
  if (typeof url === 'string' && url) {
    if (!isAbsoluteUrl(url)) {
      const {
        ossHost = 'https://zbanx-oa-image.oss-ap-southeast-1.aliyuncs.com',
      } = getAppSettings();
      if (ossHost) {
        return ossHost + '/' + url.replace(/^\/+/, '');
      }
    }
  }
  return url;
}
