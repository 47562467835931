import { SupportedLanguage } from '@/entities/SupportedLanguage';
import { getSupportedLanguages } from './getSupportedLanguages';

export const isLanguageSupported = (
  lang?: string,
): lang is SupportedLanguage => {
  if (typeof lang !== 'string') {
    return false;
  }
  return !!getSupportedLanguages()[lang as SupportedLanguage];
};
